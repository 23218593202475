import { Component, Input, OnInit ,Pipe } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-terms-condtion',
  templateUrl: './terms-condtion.page.html',
  styleUrls: ['./terms-condtion.page.scss'],
})
export class TermsCondtionPage implements OnInit {

// @Input() termsCondtion:any;

  constructor(public modalController: ModalController) { }

  ngOnInit() {
  }
  
  btndClose(){
    this.modalController.dismiss({
      'dismissed': true
    });
  }
}
